<template>
  <section class="buttons-group">
    <slot />
  </section>
</template>

<script setup lang="ts">
import type { IButtonsGroupProps } from './ButtonsGroup.types';

const props = defineProps<IButtonsGroupProps>();

const styleVariables = computed(() => ({
  borderRadius: props.borderRadius + 'px',
  direction: props.direction,
  gap: props.gap + 'px',
}));
</script>

<style scoped lang="scss">
.buttons-group {
  --border-radius: v-bind('styleVariables.borderRadius');
  --direction: v-bind('styleVariables.direction');
  --gap: v-bind('styleVariables.gap');
}
</style>

<style scoped lang="scss" src="./ButtonsGroup.scss" />
